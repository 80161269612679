<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import NewListingTable from "@/components/listings/new-listing-table";
import InputGroup from "@/components/newforms/input-group";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
// import SelectGroup from "@/components/newforms/select-group";
// import InputGroup2 from "@/components/forms/input-group";

export default {
    components: {
        Layout,
        PageHeader,
        NewListingTable,
        InputGroup,
        ConfirmationModal,
        MessageModal,
        // SelectGroup
    },
    data() {
        return {
            title: "Agent",
            items: [
                {
                    text: "Agent"
                },
            ],
            fields: [
                {
                    key: 'id',
                    label: "ID",
                    sortable: true
                },
                {
                    key: 'registration_number',
                    label: "Registration Number",
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: "First Name",
                    sortable: true
                },
                {
                    key: 'last_name',
                    label: "Last Name",
                    sortable: true
                },
                {
                    key: 'agency_name',
                    label: "Agency Name",
                    sortable: true
                },
                {
                    key: 'balance',
                    label: "Points",
                    sortable: true
                },
                { key: "action" },
            ],
            filter: {
                "agency_name": "",
                "balance_greater": "",
                "balance_less": "",
                "last_name": "",
                "first_name": "",
                "registration_number": "",
            },
            errors: {},
            // verify_options: [
            //     { text: 'All', value: "" },
            //     { text: 'Verified', value: 1 },
            //     { text: 'Not Verified', value: 0 },
            // ],
        };
    },
    computed: {

    },
    watch: {

    },
    async mounted() {
    },
    methods: {
        filterTable() {
            this.$refs.list_table.loadTable(1);
        },
        resetTable() {
            this.filter.agency_name = '';
            this.filter.balance_greater = '';
            this.filter.balance_less = '';
            this.filter.last_name = '';
            this.filter.first_name = '';
            this.filter.registration_number = '';
            this.$refs.list_table.loadTable(1);
        },
        deleteRecord(id) {
            const deleteCallback = async () => {
                await axios.delete(`${process.env.VUE_APP_APIURL}/agent/${id}`, {
                    headers: authHeader(),
                })
                .then((e) => {
                    if (e.status == 200) {
                        if (e.data.code === 'success') {
                            this.$refs.confirmationModal.closeModal();
                            this.$refs.messageModal.showModal('The record has been deleted');
                            this.$refs.list_table.loadTable(1);
                        }
                    }
                });
            }
            this.$refs.confirmationModal.showModal(`Confirm delete admin (ID: ${id})?`, deleteCallback);
        },
    }
};
</script>

<template>
    <Layout>
        <MessageModal ref="messageModal" />
        <ConfirmationModal ref="confirmationModal" />
        <PageHeader :title="title" :items="items"/>
        <b-card class="mb-2" style="border-radius: 15px;">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <InputGroup id="balance_greater" name="balance_greater" v-model="filter.balance_greater" label="Point Greater Than" placeholder="Enter points range"/>
                    <InputGroup id="balance_less" name="balance_less" v-model="filter.balance_less" label="Point Smaller Than" placeholder="Enter points range" class="mt-3"/>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <InputGroup id="last_name" name="last_name" v-model="filter.last_name" label="Agent Last Name" placeholder="Enter Agent Last Name"/>
                    <InputGroup id="first_name" name="first_name" v-model="filter.first_name" label="Agent First Name" placeholder="Enter Agent First Name"  class="mt-3"/>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <InputGroup id="agency_name" name="agency_name" v-model="filter.agency_name" label="Agency Name" placeholder="Enter Agency Name"/>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <InputGroup id="registration_number" name="registration_number" v-model="filter.registration_number" label="Registration Number" placeholder="Enter Registration Number"/>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <!-- <SelectGroup id="is_verified" name="is_verified" v-model="filter.is_verified" label="Verified" placeholder="Verified Options" :options="verify_options"/> -->
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 d-flex justify-content-end" style="margin-top: 12px;">
                    <button @click="filterTable" class="btn btn-orange">Filter</button>
                    <button @click="resetTable" class="btn btn-filter" style="margin-left: 11px;">Reset</button>
                </div>
            </div>
        <!-- </b-card>
        <b-card> -->
            <NewListingTable :entries="parseInt(10)" :filter="filter" :fields="fields" path="agent-list" ref="list_table">
                <template v-slot:cell(action)="{ item }">
                    <!-- <router-link
                    class="mr-2 text-primary"
                    :to="{ name: 'Agent View', params: { id: item.id } }">
                        <i class="ri-eye-line font-size-18" v-b-tooltip.hover title="View"></i>
                    </router-link> -->
                    <router-link class="mr-2 grey-icon-color" :to="{name: 'Agent View', params: {id: item.id}}"><i class="ri-eye-line font-size-18" v-b-tooltip.hover title="View"></i></router-link>
                    <router-link class="mr-2 grey-icon-color" :to="{name: 'Agent Form', params: {id: item.id}}"><i class="ri-pencil-line font-size-18" v-b-tooltip.hover title="Edit"></i></router-link>
                    <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
                        @click="deleteRecord(item.id)">
                        <i class="ri-delete-bin-line font-size-18"></i>
                    </a>
                </template>

                <template v-slot:cell(is_verified)="{ item }">
                    <b-badge :variant="parseInt(item.is_verified) == 1 ? 'success' : 'danger' ">{{ parseInt(item.is_verified) == 1 ? 'Verified' : "Not Verified" }}</b-badge>
                </template>
            </NewListingTable>
        </b-card>
    </Layout>
</template>